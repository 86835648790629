import styled from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const HeaderSpots = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .city-name {
    margin: 0;
  }

  .arrow {
    margin: 5px 20px;
    line-height: 20px;
    font-size: 20px;
  }

  @media (max-width: 720px) {
    justify-content: center;
    align-items: center;
    height: 50px;

    .city-name {
      font-size: 17px;
    }
  }
`;

export const HeaderSub = styled.div`
  span {
    margin-right: 15px;

    font-size: 24px;
    font-weight: 600;
    color: #009788;
  }
`;

export const SegmentContentSlideNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 10px;
`;

export const SegmentContentSlideNavButton = styled.div`
  margin: 0 10px;
  flex: 1;
  height: 10px;
  border-radius: 6px;
  background-color: ${({ selected }) =>
    selected ? COLORS.KEPPEL_02 : '#EBEBEB'};
`;
