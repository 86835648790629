// TODO: REFACTORING
import React from 'react';
import * as Styled from './Header.style';

import Arrow from '../Arrow';
import Button from '../Button';
import Price from '../Price';

import { translate as t } from '@utils/translate';

const HeaderDesktop = ({
  originSpot,
  destinationSpot,
  value,
  currency,
  pathcount,
  showButtonDetails,
  onClickMoreDetails,
}) => {
  const TEXTS = {
    BUTTON_DETAILS_LABEL: t('proposalDetails'),
  };

  return (
    <>
      <div className={'header__path'}>
        <Styled.HeaderSpots>
          <h2 className={'city-name'}>{originSpot.address.city.name}</h2>
          <Arrow direction={'right'}></Arrow>
          <h2 className={'city-name'}>{destinationSpot.address.city.name}</h2>
        </Styled.HeaderSpots>

        <hr></hr>

        <Styled.HeaderSub>
          <span>{pathcount}</span>
        </Styled.HeaderSub>
      </div>

      <div className={'header__price'}>
        <Price values={value} currencies={currency} />
        {showButtonDetails && (
          <Button
            theme={'active'}
            label={TEXTS.BUTTON_DETAILS_LABEL}
            onClick={onClickMoreDetails}
          />
        )}
      </div>
    </>
  );
};

const HeaderMobile = ({
  segments,
  originSpot,
  destinationSpot,
  currentSlide,
  onClickNavButton,
  showNav,
}) => {
  return (
    <>
      <div className={'header__path'}>
        <Styled.HeaderSpots>
          <h2 className={'city-name'}>{originSpot.address.city.name}</h2>
          <Arrow direction={'right'}></Arrow>

          <h2 className={'city-name'}>{destinationSpot.address.city.name}</h2>
        </Styled.HeaderSpots>

        <SegmentContentMobileNav
          segments={segments}
          currentSlide={currentSlide}
          onClick={onClickNavButton}
          show={showNav && segments.length > 1}
        ></SegmentContentMobileNav>
      </div>
    </>
  );
};

const SegmentContentMobileNav = ({ segments, currentSlide, onClick, show }) => {
  if (!show) return null;
  return (
    <Styled.SegmentContentSlideNav>
      {segments.map((segment, index) => (
        <Styled.SegmentContentSlideNavButton
          key={index}
          selected={index === currentSlide}
          onClick={() => onClick(index)}
        ></Styled.SegmentContentSlideNavButton>
      ))}
    </Styled.SegmentContentSlideNav>
  );
};

const Header = ({
  isMobile,
  segments,
  originSpot,
  destinationSpot,
  value,
  currency,
  pathcount,
  showButtonDetails,
  onClickMoreDetails,
  currentSlide,
  onClickNavButton,
  showNav,
}) => {
  if (isMobile) {
    return (
      <HeaderMobile
        segments={segments}
        originSpot={originSpot}
        destinationSpot={destinationSpot}
        currentSlide={currentSlide}
        onClickNavButton={onClickNavButton}
        showNav={showNav}
      />
    );
  } else {
    return (
      <HeaderDesktop
        originSpot={originSpot}
        destinationSpot={destinationSpot}
        value={value}
        currency={currency}
        pathcount={pathcount}
        showButtonDetails={showButtonDetails}
        onClickMoreDetails={onClickMoreDetails}
      />
    );
  }
};

export default Header;
